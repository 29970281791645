.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.breadcrumbs-link {
  text-decoration: none;
}

:fullscreen::backdrop {
  background: white;
}

.fullscreen-enabled .ResponsiveContainer {
  background: rgb(255, 255, 255) !important;
  width: 100% !important;
  min-height: 100% !important;
}
.ContentHeader {
  font: normal 15px / 26px "Open Sans", Helvetica, Arial, Verdana, sans-serif;
  word-spacing: normal;
  color: #6b6b6b;
  font-weight: 700;
  line-height: 44px;
}
.ContentBody {
  font: normal 13px / 24px "Open Sans", Helvetica, Arial, Verdana, sans-serif;
  word-spacing: normal;
  color: #6b6b6b;
  font-weight: 700;
  line-height: 44px;
}

.colorize-icon {
  color: #e6e6e6;
}

.colorize-icon:hover {
  color: grey;
}

.picker {
  position: relative;
}

.popover {
  position: absolute;
  top: calc(100% + 15px);
  right: calc(100% + -30px);
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 2000;
}

.loader {
  color: #000000;
  display: inline-block;
  position: relative;
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}
.loader::after {
  content: "";
  width: 5px;
  height: 5px;
  background: currentColor;
  position: absolute;
  bottom: 5px;
  right: -5px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 10px 0 rgba(161, 44, 44, 0), 20px 0 rgba(99, 19, 19, 0);
  }
  50% {
    box-shadow: 10px 0 rgb(0, 0, 0), 20px 0 rgba(159, 111, 111, 0);
  }
  100% {
    box-shadow: 10px 0 rgb(0, 0, 0), 20px 0 rgb(0, 0, 0);
  }
}
